<template>
    <v-app>
        <NavBar />
        <ArtistProfile :artist="artist"/>
        <ProductsPage :products="products" :artist="1" @searchProduct="getProducts"/>
        <SearchSection />
        <FooterSection />
    </v-app>
</template>

<script>
import NavBar from '@/components/default/NavBar.vue';
import ArtistProfile from '@/components/artists/ArtistProfile.vue';
import ProductsPage from '@/components/productspage/ProductsPage.vue';
import SearchSection from '@/components/default/SearchSection.vue';
import FooterSection from '@/components/default/FooterSection.vue';
import api from "@/commands/api"

export default {
    name: 'ProfileView',
    components: {
        NavBar,
        ArtistProfile,
        ProductsPage,
        SearchSection,
        FooterSection
    },
    data() {
        return {
            loading: false,
            artist: {},
            products:[]
        };
    },
    async created() {
        this.loading = true;
        await this.getArtist();
        await this.getProducts();
    },
    methods: {
        async getArtist() {
            try {
                const response = await api.get(`/store/${this.$route.params.slug}`);
                this.artist = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;    
            }
        },
        async getProducts(search = '', filters = {}) {
            try {
                let q = '';
                q += `?s=${this.artist.id}&o=id&ot=desc`;
                if(search) {
                    q = `?q=${search}`;
                }

                if(filters.price) {
                    q += `&min=${filters.price[0]}&max=${filters.price[1]}`;
                }

                if(filters.sizes) {
                    q += `&v=${filters.sizes.filter(item => item.selected).map(item => item.name).join(',')}`;
                }

                const response = await api.get(`/products${q}`);
                this.products = response.data.data;
                this.productsMeta = response.data.meta;
            } catch (error) {
                console.log(error);
            }
        }
    }

};
</script>

<style scoped>
    
</style>