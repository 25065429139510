<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="artist-card">
                    <div class="body">
                        <div class="img" @click="goTo(`/produto/${product.handle}`)">
                            <zoom-on-hover 
                                :img-normal="productShow.image"
                                :scale="1"
                            >
                            </zoom-on-hover>
                        </div>
                        <v-icon class="heart" ref="heart" @click="handleFavorite(iconName == 'far fa-heart' ? true : false)">
                            {{ iconName }}
                        </v-icon>
                        <h5>{{ productShow.name.length > 18 ? productShow.name.substring(0, 18) + '...' : productShow.name }}</h5>
                        <small>{{ productShow.artist }}</small>
                    </div>
                    <div class="footer" @click="goTo(`/produto/${product.handle}`)">
                        <button class="lda-btn lda-btn-default"> {{ productShow.value.toLocaleString('pt-BR', { style:  'currency', currency: 'BRL' }) }}</button>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ProductCard',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            productShow: {
                id: 0,
                name: '',
                image: '',
                artist: '',
                description: '',
                value: 0
            },
            iconName: 'far fa-heart'
        }
    },
    mounted() {
        this.productShow.id = this.product.id
        this.productShow.name = this.product.name
        this.productShow.artist = this.product.store.name
        this.productShow.image = this.product.images.filter(x => x.position == 1)[0].src
        this.productShow.description = this.product.description
        this.productShow.value = this.product.salePrice == null ? 0 : this.product.salePrice

        this.iconName = this.isFavorite() ? 'fa fa-heart' : 'far fa-heart';
    },
    methods:{
        handleFavorite(e){
            if(e){
                let favorites = localStorage.getItem('favorites');
                if(favorites){
                    favorites = JSON.parse(favorites);
                    favorites.push(this.product.id);
                    localStorage.setItem('favorites', JSON.stringify(favorites));
                }else{
                    localStorage.setItem('favorites', JSON.stringify([this.product.id]));
                }
                this.iconName = 'fa fa-heart';
            }else{
                let favorites = localStorage.getItem('favorites');
                if(favorites){
                    favorites = JSON.parse(favorites);
                    favorites = favorites.filter(favorite => favorite !== this.product.id);
                    localStorage.setItem('favorites', JSON.stringify(favorites));
                }
                this.iconName = 'far fa-heart';
            }
        },
        isFavorite(){
            let favorites = localStorage.getItem('favorites');
            if(favorites){
                favorites = JSON.parse(favorites);
                return favorites.includes(this.product.id);
            }else{
                return false;
            }
        },
        goTo(route){
            this.$router.push(route);
        }
    }
}
</script>

<style scoped>
    .artist-card img {
        width: 100%;
        border-radius: 10px;
        border: 2pt solid var(--color-primary);
    }
    .artist-card .img {
        cursor: zoom-in;
        border-radius: 10px;
        padding: 5px;
        border: 2pt solid var(--color-primary);
    }
    .artist-card h5 {
        margin-top: 10px;
        margin-bottom: -6px;
        font-size: .8em;
        font-family: "Tomorrow", sans-serif;
    }
    .artist-card .body{
        padding-bottom: 10px;
    }

    .artist-card .heart{
        width: 25px;
        float: right;
        margin-top: 15px;
        color: var(--color-primary);
    }
</style>